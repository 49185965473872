import React from "react"
import Hero, { HeroContent } from "../components/hero"
import LayoutDefault from "../layouts/default"
import Seo from "../components/seo"

const NotFoundPage = () => {
  return (
    <LayoutDefault>
      <Seo
        title="Ошибка 404: Страница не неайдена"
        description="Страница не неайдена"
        url="/"
      />
      <Hero backLink="/">
        <HeroContent>
          <h1 className="heading">Страница не найдена</h1>
          <p>
            Упс! Вероятно страница была удалена или перенесена по новому адресу.
          </p>
        </HeroContent>
      </Hero>
    </LayoutDefault>
  )
}

export default NotFoundPage
